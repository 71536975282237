
import * as React from "react";
import { useState } from "react";
import { Transition } from '@headlessui/react';
import { Link } from "gatsby";

const Testimonials = () => {

    const testimonialContent = [

        {
            name: "Dr. Scott Kollen", 
            practice:"River District Dentistry", 
            review: "Working with HealthOne Digital on my dental practice's digital needs over the past few years has been wonderful! His ideas and work are excellent, and have that 'WOW' factor, and he's very accommodating when it comes to modifications and special requests. Thanks so much for helping my new clinic grow and thrive!"
        },
        {
            name: "Gurinder Hothi & Vic Sangher",
            practice: "River District Physiotherapy",
            review: "When we started our River District Physio, the motivation was to simply create. From creating a strong team, to the physical clinic construction, to our digital presence, we wanted to build a brand that embodied our vision and passion. HealthOne Digital was instrumental in bringing that vision to life with his work on the River District Physio brand."
        },
        {
            name: "Dr. Adam Kennedy",
            practice: "Kennedy & Mecca Family Dentistry",
            review: "HealthOne Digital truly raises the bar. They were able to convert my outdated website into a modern, and fully customized work of art. Not only that, they were extremely helpful with my branding, creating new logos, business cards, stationary, etc. They are prompt, extremely professional, and I'm confident you will be glad you chose their team of experts."
        },
        
    ]

    return( 
   

   
    <section className="bg-blue-50 py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-gray-600">TESTIMONIALS</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-blue-900 sm:text-4xl">
              See What Our Clients Say
            </p>
          </div>
          </div>
        
          <div className="max-w-7xl mx-auto md:grid md:grid-cols-3 md:px-6 lg:px-8">

          {testimonialContent.map((testimonial) => (
                <div key={testimonial.name} className="pt-8 sm:inline-block sm:w-full sm:px-4">
                  <figure className="rounded-2xl bg-gray-50 p-8 text-base leading-7 border border-gray-300">
                    <blockquote className="text-blue-900 text-xl leading-8">
                      <p>{`“${testimonial.review}”`}</p>
                    </blockquote>
                    <figcaption className="mt-6 flex items-center gap-x-4">
                      {/* <img alt="" src={""} className="h-10 w-10 rounded-full bg-gray-50" /> */}
                      <div>
                        <div className="font-semibold text-blue-800">{testimonial.name}</div>
                        <div className="text-gray-600">{testimonial.practice}</div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              ))}

          </div>
                                 
  </section> 
 
  
  );
}

export default Testimonials;