import * as React from "react";
import { Link } from "gatsby";

import DoctorVR from '../images/doctor-vr.jpg';
import MedIcon1 from "../images/medical-icons/1.png"
import MedIcon2 from "../images/medical-icons/2.png"
import MedIcon3 from "../images/medical-icons/3.png"
import MedIcon4 from "../images/medical-icons/4.png"
import MedIcon5 from "../images/medical-icons/5.png"
import MedIcon6 from "../images/medical-icons/6.png"
import MedIcon7 from "../images/medical-icons/7.png"
import MedIcon8 from "../images/medical-icons/8.png"
import MedIcon9 from "../images/medical-icons/9.png"
import MedIcon10 from "../images/medical-icons/10.png"
import MedIcon11 from "../images/medical-icons/11.png"
import MedIcon12 from "../images/medical-icons/12.png"
import MedIcon13 from "../images/medical-icons/13.png"
import MedIcon14 from "../images/medical-icons/14.png"
import MedIcon15 from "../images/medical-icons/15.png"
import MedIcon16 from "../images/medical-icons/16.png"
import MedIcon17 from "../images/medical-icons/17.png"
import MedIcon18 from "../images/medical-icons/18.png"
import MedIcon19 from "../images/medical-icons/19.png"
import MedIcon20 from "../images/medical-icons/20.png"
import SEO from '../components/Seo';

const WhoWeHelp = () => {

    const servicesList1 = [
        "Addiction Treatment",
        "Allergy & Immunology",
        "Alternative Medicine",
        "Assisted Living",
        "Audiology / Otology",
        "Bariatric Surgery",
        "Cancer Care / Oncology",
        "Cardiology",
        "Chiropractor",
        "Dentistry",
        "Dermatology",
        "Endocrinology",
        ,
    ]

    const servicesList2 = [
        "Gastroenterology",
        "General / Family Practice",
        "General Surgery",
        "Hospice Care",
        "Lasik",
        "Mental Health",
        "Neurosurgery / Neurology",
        "Obstetrics & Gynecology",
        "Optometry / Ophthalmology",
        "Orthodontics",
        "Orthopedics",
        "Otolaryngology / ENT",
    ]

    const servicesList3 = [
        "Pain Management",
        "Pediatrics",
        "Physical Therapy",
        "Plastic / Cosmetic Surgery",
        "Podiatry",
        "Rehabilitation",
        "Rheumatology",
        "Urgent Care",
        "Urology",
        "Veterinary Medicine",
        "Wellness / Nutrition",
    ]

    return(
       
        <div>
        {/* WHO WE HELP SECTION */}
            <section>
                <div className="relative">
                    <div className="max-w-screen mx-auto">
                        <div className="relative sm:overflow-hidden">
                            <div className="absolute inset-0">
                                <img className="h-full w-full object-cover" src={DoctorVR} alt="Doctor using VR headset."/>
                                <div className="absolute inset-0 bg-blue-900" style={{'mix-blend-mode': 'multiply'}}></div>
                            </div>

                            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">  
                            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                <span className="block text-white">We Work With All Types Of</span>
                                <span className="block text-blue-200">Healthcare Providers</span>
                            </h1>
                            
                            <div className="max-w-7xl text-center mx-auto mt-10">
                                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                                    <div className="relative">
                                        <dt>
                                            {servicesList1.map((service, index) => <p key={index} className="sm:ml-0 lg:ml-16 mt-2 text-xl leading-6 font-medium text-white">{service}</p> )}
                                        </dt>
                                    </div>

                                    <div className="relative">
                                    <dt>
                                        {servicesList2.map((service, index) => <p key={index} className="sm:ml-0 lg:ml-16 mt-2 text-xl leading-6 font-medium text-white">{service}</p> )}
                                    </dt>
                                    
                                    </div>

                                    <div className="relative">
                                    <dt>
                                        {servicesList3.map((service, index) => <p key={index} className="sm:ml-0 lg:ml-16 mt-2 text-xl leading-6 font-medium text-white">{service}</p> )}
                                    </dt>
                                
                                    </div>

                                </dl>
                            </div>

                            <div className="mt-16 flex flex-row flex-wrap space-x-5 space-y-2 justify-center">
                                    <img src={MedIcon1} className="w-10 h-10" alt="Medical Services Icon 1" />
                                    <img src={MedIcon2} className="w-10 h-10" alt="Medical Services Icon 2" />
                                    <img src={MedIcon3} className="w-10 h-10" alt="Medical Services Icon 3" />
                                    <img src={MedIcon4} className="w-10 h-10" alt="Medical Services Icon 4" />
                                    <img src={MedIcon5} className="w-10 h-10" alt="Medical Services Icon 5" />
                                    <img src={MedIcon6} className="w-10 h-10" alt="Medical Services Icon 6" />
                                    <img src={MedIcon7} className="w-10 h-10" alt="Medical Services Icon 7" />
                                    <img src={MedIcon8} className="w-10 h-10" alt="Medical Services Icon 8" />
                                    <img src={MedIcon9} className="w-10 h-10" alt="Medical Services Icon 9" />
                                    <img src={MedIcon11} className="w-10 h-10" alt="Medical Services Icon 10" />
                                    <img src={MedIcon12} className="w-10 h-10" alt="Medical Services Icon 11" />
                                    <img src={MedIcon20} className="w-10 h-10" alt="Medical Services Icon 12" />
                                    <img src={MedIcon14} className="w-10 h-10" alt="Medical Services Icon 13" />
                                    <img src={MedIcon16} className="w-10 h-10" alt="Medical Services Icon 14" />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           
            </div>
    )
}

export default WhoWeHelp;